<template>
    <div class="home-page">
      <div class="app-box">
        <div v-for="item in menuData" :key="item.AppId" class="app-box-item">
          <div class="app-box-head">
            <div class="app-item-icon">
              <i class="el-icon-notebook-2" style="font-size: 20px;color:#3e8bda;"></i>
            </div>
            <div class="app-box-title">
              {{item.AppName}}
            </div>
          </div>
          <div class="app-box-btn" @click="() =>menuItemClickHandle(item)">
            进入系统
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
   import Event from '../../../scripts/event.js'
  export default {
    data() {
      return {
        menuData: []
      }
    },
    mounted() {
      this.getAppList()
    },
    methods: {
      getAppList() {
        var me = this;
        me.$AjaxRequest.AjaxSubmit(window.AppConfig.apiBaseUrl + "omsapi/sysmenu/getapplist", "get", null, result => {
            me.menuData = result.Result;
            console.log(me.menuData);
        }, null, true);
      },
      menuItemClickHandle(item, e) {
        if (item.Items && item.Items.length > 0)
          return
        if (!item.IsExternal) {
          Event.$emit("initlizeCustomerMenus", item.AppId);
          // this.activeIndex = item.AppId;
        }
        else
          if (item.RedirectUrl) window.location.href = item.RedirectUrl;
      },
    }
  }
  </script>
  
  <style scoped>
  @import "../../../assets/css/index.css";
  .app-box-item {
    width: 310px;
    height: 150px;
    border: 1px solid #aac9e7;
    background-color: #fdfdfe;
    border-radius: 4px;
    margin-right: 24px;
    margin-bottom: 20px;
    position: relative;
  }
  .app-box-head {
    padding: 10px;
    background-color: #fdfdfe;
    display: flex;
    justify-content: flex-start;
  }
  .app-box-title {
    color: #333;
    font-size: 16px;
    padding-top: 5px;
  }
  .app-box-btn {
    width: 100%;
    height: 26px;
    line-height: 26px;
    color: #fff;
    background-color: #3c8ad9;
    text-align: center;
    cursor: pointer;
    position: absolute;
    left: 0;
    bottom: 20px;
  }
  .app-box {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .app-item-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    background-color: #e9f5ff;
    margin-right: 10px;
  }
  </style>